/* eslint-disable import/prefer-default-export */
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

export const tableRequestEdit = [
  {
    key: 'request_created_at',
    label: 'Tanggal',
    sortable: true,
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
    },
    tdClass: 'text-black',
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'full_name',
    label: 'Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'talent_lead',
    label: 'Talent Lead',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'role',
    label: 'Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'talent_status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
      background: '#F4F4F4',
    },
    tdClass: 'text-black capitalize',
    class: 'p-1',
  },
  {
    key: 'action',
    label: '',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]
