<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-1">
      Request Edit
    </div>
    <BTabs
      v-model="tabIndex"
      class="mt-2"
      content-class="mt-1"
      pills
      active-nav-item-class="rounded-pill"
    >
      <BTab
        :active="$route.query.tab === 'request'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{ `Request Edit` }}
          <span class="rounded-full w-[24px] h-[24px] bg-white text-[#333] flex items-center justify-center ml-[10px]">{{ dataTotal.total }}</span>
        </template>
        <Request />
      </BTab>
      <BTab
        :active="$route.query.tab === 'history'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{ `Riwayat Update` }}
        </template>
        <History />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import Request from './status/Request.vue'
import History from './status/History.vue'

export default {
  components: {
    Request,
    History,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ['request', 'history'],
      dataTotal: {},
    }
  },
  watch: {
    tabIndex(newTab, oldtab) {
      const tab = this.tabs[newTab]
      this.$router.replace({ query: { tab } }).catch(err => {})

      this.getTotal()
    },
  },
  mounted() {
    this.getTotal()

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

    const tab = this.tabs[0]
    this.$router.replace({ query: { tab } }).catch(err => {})
  },
  methods: {
    getTotal() {
      const url = 'v1/talent_update/count'
      komtimAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.dataTotal = data
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          this.$toast_error({ message: 'get data product option failed' })
        })
    },
  },
}
</script>
